<template>
  <div class="lcf-not-found-page">
    <NavBar left-arrow fixed @click-left="$router.go(-1)" placeholder></NavBar>
    <Empty
      class="lcf-empty"
      image="search"
      description="页面不存在，看看其他地方吧~"
    />
  </div>
</template>

<script>
import { Empty, NavBar } from 'vant'

export default {
  components: {
    Empty,
    NavBar,
  },
}
</script>

<style lang="scss" scoped>
.lcf-not-found-page {
  height: 100%;
  .lcf-empty {
    margin: 48px 0 0;
  }
}
</style>
